import React from 'react'
import { Container } from 'react-bootstrap'

const FindStore = () => {
  return (
    <div style={{ minHeight: '60vh' }}>
      <Container>
      <h1 style={{  padding: '50px 0', fontWeight: 'bold' }}>Find a Store</h1>

      </Container>

      
    </div>
  )
}

export default FindStore
