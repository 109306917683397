import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Dropdown, DropdownButton, Form, Toast } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Colors from '../shared/Colors';
import { FiPlus, FiMinus } from "react-icons/fi"
import { FaAngleDown, FaShare } from "react-icons/fa"
import 'intl'
import { setLoginShow, getUser, setCheckoutDetails, setIdn } from '../redux/actions';
// import { collection, getDocs, doc, getDoc, query, where, onSnapshot, Timestamp } from 'firebase/firestore';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../util/firebase';
// import { setCheckoutDetails, getCartItems, selectedExcursion, getBookings, getOrders } from '../redux/actions';
import { useNavigate } from "react-router-dom";
import moment from 'moment/moment';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from '../shared/CheckoutForm';


import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import PaymentsModal from '../shared/PaymentsModal';
import axios from 'axios';
import { STRIPE_TEST_PUBLISHABLE_KEY, STRIPE_PUBLISHABLE_KEY } from '../api/constants';
// const stripePromise = loadStripe(STRIPE_TEST_PUBLISHABLE_KEY);
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY );
const SingleExcursion = (props) => {

    const heights = [150, 30, 90, 70, 110, 150, 130, 80, 50, 90, 100, 150, 30, 50, 80];


    const [clientSecret, setClientSecret] = useState("");

    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
    const [showC, setShowC] = useState(false);
    const [showD, setShowD] = useState(false);
    const [showE, setShowE] = useState(false);
    const [showF, setShowF] = useState(false);
    const [showG, setShowG] = useState(false);
    const [showH, setShowH] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);
    const toggleShowC = () => setShowC(!showC);
    const toggleShowD = () => setShowD(!showD);
    const toggleShowE = () => setShowE(!showE);
    const toggleShowF = () => setShowF(!showF);
    const toggleShowG = () => setShowG(!showG);
    const toggleShowH = () => setShowH(!showH);

    const [payments, setPayments] = useState(false)

    console.log('Date', Date.now())

    const data = [
        { label: '8:00 AM', value: '8:00 AM' },
        { label: '9:00 AM', value: '9:00 AM' },
        { label: '10:00 AM', value: '10:00 AM' },
        { label: '11:00 AM', value: '11:00 AM' },
        { label: '12:00 PM', value: '12:00 PM' },
        { label: '1:00 PM', value: '1:00 PM' },
        { label: '2:00 PM', value: '2:00 PM' },
        { label: '3:00 PM', value: '3:00 PM' },
        { label: '4:00 PM', value: '4:00 PM' },
        { label: '5:00 PM', value: '5:00 PM' },
    ];




    // const Item = styled(Paper)(({ theme }) => ({
    //     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //     ...theme.typography.body2,
    //     padding: theme.spacing(0.5),
    //     textAlign: 'center',
    //     color: theme.palette.text.secondary,
    //   }));

    const user = useSelector(state => state.user)
    const [excursion, setExcursion] = useState(useSelector(state => state.selectedExcursion) || null)
    const locations = useSelector(state => state.locations)
    const isAuth = useSelector(state => state.isAuth)
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(data[0].value)
    const [adults, setAdults] = useState(excursion?.data.category === 'Cruise' ? excursion?.data.quantity : 1)
    const [location, setLocation] = useState({ cost: 0, town: 'No Pickup' })
    const [pickup, setPickup] = useState(false)
    const [pickupTime, setPickupTime] = useState(data[0].value)
    const [pickupDetails, setPickupDetails] = useState('')
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: "AIzaSyBccuAvZgOpjNrFxylUqGTwud5rZqxA5Y0" })
    const [showMap, setShowMap] = useState(false)

    const [isLocal, setIsLocal] = useState(false)

    const [photography, setPhoto] = useState(false)
    const [photographyCost, setPhotCost] = useState(0)

    const [catering, setCatering] = useState(false)
    const [cateringCost, setCateringCost] = useState(0)

    const [videography, setVideo] = useState(false)
    const [videographyCost, setVideoCost] = useState(0)

    const [firstName, setFirstName] = useState(user?.data.firstName || '')
    const [lastName, setLastName] = useState(user?.data.lastName || '')
    const [email, setEmail] = useState(user?.data.email || '')


    const [resData, setData] = useState(null)

    const [pvText, setPVText] = useState('No Photography or Videography')

    const dispatch = useDispatch()
    const navigate = useNavigate();
    // const iFrame = document.getElementById('fac-hosted')

    const [showCheckout, setShowCheckout] = useState(false)

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    // const getBookings = async () => {
    //     const coll = collection(firestore, 'bookings')
    //     const q = query(coll, where('userId', '==', auth.currentUser?.uid), where('excursionId', '==', excursion.id))
    //     onSnapshot(q, (querySnapshot) => {
    //         if (querySnapshot.docs.length > 0) {
    //             setShowMap(true)
    //         }

    //     })
    // }

    const getExcursion = async () => {
        const prod = await getDoc(doc(firestore, 'excursions', window.location.pathname.substring(50, 11)))
        // console.log('new Product', prod.data())
        setExcursion({ id: prod?.id, data: prod?.data() })

    }

    const createPaymentIntent = () => {
        fetch('https://api-lkdtcse4yq-uc.a.run.app/create-payment-intent',
        // fetch("http://localhost:8000/create-payment-intent",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ items: [{ id: excursion.id, name: excursion.data.name, quantity: adults, cost: parseInt((adults * excursion.data.cost) + (pickup ? location.cost * adults : 0) + cateringCost + videographyCost + photographyCost) }] }),
            })
            .then((res) => res.json())
            .then((data) => {
                setShowCheckout(true)
                setClientSecret(data.clientSecret)
            });

    }

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        getExcursion()
        if (user) {
            // getBookings()
        }

    }, [user])


    if (excursion === null) {
        getExcursion()
        return (<div style={{ minHeight: '60vh' }}></div>)
    }



    return (
        <div>

            <Container style={{ marginBottom: 40, marginTop: 40 }}>
                <Row>
                    <Col md={12}>
                        <h1 className='custom-font' style={{ margin: 0 }}>  {excursion?.data.name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <h6 className='custom-font' style={{}}>{excursion.data?.location.town}, {excursion?.data.location.parish}, Jamica</h6>
                    </Col>
                    <Col md={2}>
                        <h6 style={{ fontWeight: 'bold', cursor: 'pointer', textAlign: 'right' }} onClick={() => toggleShowG()}><FaShare size={20} color='silver' /> Share</h6>
                        <Toast show={showG} onClose={toggleShowG} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                            <Toast.Body>
                                <EmailShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <EmailIcon size={45} round={true} />
                                </EmailShareButton>

                                <FacebookShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <FacebookIcon size={45} round={true} />
                                </FacebookShareButton>

                                <WhatsappShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <WhatsappIcon size={45} round={true} />
                                </WhatsappShareButton>

                                <TelegramShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <TelegramIcon size={45} round={true} />
                                </TelegramShareButton>

                                <TwitterShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <TwitterIcon size={45} round={true} />
                                </TwitterShareButton>

                                <PinterestShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <PinterestIcon size={45} round={true} />
                                </PinterestShareButton>

                                <LinkedinShareButton url={window.location.href} style={{ marginRight: 10, marginBottom: 10 }}>
                                    <LinkedinIcon size={45} round={true} />
                                </LinkedinShareButton>

                            </Toast.Body>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={() => { toggleShowG() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                    close
                                </Button>
                            </div>
                        </Toast>
                    </Col>
                    {/* <Col md={1}><h6 style={{ fontWeight: 'bold' }}>< FaRegHeart size={20} color='silver' /> Save</h6></Col> */}

                </Row>




                <div style={{
                    backgroundImage: `url(${excursion?.data.images[0]})`,
                    height: 600,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    borderRadius: 10,
                    overflow: 'hidden',
                    marginBottom: 30
                }}>


                </div>


                <Row style={{ position: 'relative' }}>
                    <Col md={7} style={{ padding: 10 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Description</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {excursion?.data.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Inclusions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <ul>
                                        {
                                            excursion?.data.includes?.map((item, idx) => <li key={idx}>{item}</li>)
                                        }
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>What to bring</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {excursion?.data.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Important Information</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {excursion?.data.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Cancellation Policy</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {excursion?.data.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Col>

                    <Col md={5} style={{ padding: 10, position: 'relative' }}>
                        <div style={{ width: 375, marginLeft: 'auto', marginRight: 'auto', border: '1px solid rgb(221, 221, 221)', boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px', padding: 10, borderRadius: 12, top: 0 }}>
                            <Row>
                                <Col md={6}>

                                </Col>
                                <Col md={6}></Col>
                            </Row>
                            <h2 className='custom-font'>
                                {isLocal ? formatter.format((excursion?.data.cost * 160) - (excursion?.data.cost * 160) * .37) : formatter.format(excursion?.data.cost)}

                                <h5 className='custom-font' style={{ display: 'inline-block', fontWeight: 'bold', fontSize: 12 }}>
                                    {isLocal ? 'JM' : 'US'} /person</h5>
                            </h2>
                            {/* <Row>
                                <Col sm={6}><h6 style={{ fontWeight: 'bold' }}>I am Local</h6></Col>

                                <Col sm={6}>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Check type='radio' name='isLocal' id='local' label='Yes' checked={isLocal} onClick={() => setIsLocal(true)} />
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Check type='radio' name='isLocal' id='local' label='No' checked={!isLocal} onClick={() => setIsLocal(false)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}



                            <Row>
                                <Col md={6}>
                                    <Button onClick={() => { toggleShowA(); setShowB(false); setShowC(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%' }}>
                                        <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Date</h6>
                                        <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{moment(date).format('ddd MMM DD, yy').toString()}</p>
                                    </Button>
                                    <Toast show={showA} onClose={toggleShowA} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                        <Toast.Body>
                                            <Calendar
                                                className={"react-calendar"}
                                                minDate={new Date()}
                                                onChange={(value) => { setDate(value); toggleShowA() }} value={date} /></Toast.Body>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button onClick={() => { toggleShowA() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                close
                                            </Button>
                                        </div>
                                    </Toast>
                                </Col>

                                <Col md={6}>
                                    <Button onClick={() => { toggleShowB(); setShowA(false); setShowC(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%' }}>
                                        <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Time</h6>
                                        <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{time}</p>
                                    </Button>
                                    <Toast show={showB} onClose={toggleShowB} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                        <Toast.Body>
                                            <Row style={{ maginBottom: 50 }}>
                                                {data.map(tim =>

                                                    <Col md={4} >
                                                        <Button onClick={() => { setTime(tim.value); toggleShowB() }} style={{ backgroundColor: 'transparent', border: time === tim.value ? '1px solid blue' : '1px solid black', color: 'black', width: '100%', fontSize: 14, marginBottom: 10 }}>
                                                            {tim.label}
                                                        </Button>
                                                    </Col>



                                                )}  </Row>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button onClick={() => { toggleShowB() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                    close
                                                </Button>
                                            </div>
                                        </Toast.Body>
                                    </Toast>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    <Button onClick={() => { toggleShowC(); setShowB(false); setShowA(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', marginTop: 10 }}>
                                        <Row>
                                            <Col md={10}>
                                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>guests</h6>
                                                <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{adults} Guest(s)</p>
                                            </Col>
                                            <Col md={2}>
                                                <FaAngleDown size={20} color='black' style={{ marginTop: 10 }} />
                                            </Col>
                                        </Row>

                                    </Button>

                                    <Toast show={showC} onClose={toggleShowC} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                        <Toast.Body>
                                            <Row>
                                                <Col md={6}>
                                                    <p>GUESTS</p>
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => {

                                                            if (excursion?.data.category === 'Cruise' && adults > excursion?.data.quantity) {
                                                                setAdults(adults - 1)
                                                            } else
                                                                if (excursion?.data.category !== 'Cruise' && adults > 1) {

                                                                    setAdults(adults - 1)
                                                                }
                                                        }
                                                        }>
                                                            <FiMinus size={20} color={'gray'} style={{ marginBottom: 5 }} />
                                                        </Button>
                                                        {adults}
                                                        <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { setAdults(adults + 1) }}>
                                                            <FiPlus size={20} color={'gray'} style={{ marginBottom: 5 }} />
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Toast.Body>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button onClick={() => { toggleShowC() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                close
                                            </Button>
                                        </div>
                                    </Toast>
                                </Col>
                            </Row>






                            <Row>
                                <Col md={12}>

                                    <Button onClick={() => { toggleShowD(); setShowB(false); setShowA(false); setShowC(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', marginTop: 10 }}>
                                        <Row>
                                            <Col md={10}>
                                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Pick up</h6>
                                                <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{location.town} - {formatter.format(location.cost * adults)} </p>
                                            </Col>
                                            <Col md={2}>
                                                <FaAngleDown size={20} color='black' style={{ marginTop: 10 }} />
                                            </Col>
                                        </Row>

                                    </Button>

                                    <Toast show={showD} onClose={toggleShowD} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                        <Toast.Body>
                                            <Row>
                                                <Col md={6}>
                                                    <p style={{ cursor: 'pointer' }} onClick={() => { setLocation({ cost: 0, town: 'No Pickup' }); setPickup(false); setPickupDetails(''); toggleShowD() }}>No Pickup</p>
                                                </Col>
                                                {locations.map((loca) =>
                                                    <Col md={6}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setLocation({ cost: loca?.data.cost, town: loca?.data.town }); setPickup(true); toggleShowD() }}>{loca?.data.town}</p>
                                                    </Col>
                                                )}


                                            </Row>
                                        </Toast.Body>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button onClick={() => { toggleShowD() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                close
                                            </Button>
                                        </div>
                                    </Toast>
                                </Col>

                                <Col md={12}>
                                    {pickup && <>
                                        <div style={{ marginTop: 10 }}>
                                            <h6 style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold' }}>Pickup instructions</h6>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

                                                <Form.Control as="textarea" onChange={(value) => { setPickupDetails(value.target.value); }} rows={3} />
                                            </Form.Group>
                                            <p style={{ textAlign: 'center', fontSize: 12 }}>
                                                Please enter details about your pickup location such as Address & Information
                                            </p>
                                        </div>
                                        <Col md={12}>
                                            <Button onClick={() => { toggleShowH(); setShowA(false); setShowC(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%' }}>
                                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Pickup Time</h6>
                                                <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{pickupTime}</p>
                                            </Button>
                                            <Toast show={showH} onClose={toggleShowH} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                                <Toast.Body>
                                                    <Row style={{ maginBottom: 50 }}>
                                                        {data.map(tim =>

                                                            <Col md={4} >
                                                                <Button onClick={() => { setPickupTime(tim.value); toggleShowH() }} style={{ backgroundColor: 'transparent', border: pickupTime === tim.value ? '1px solid blue' : '1px solid black', color: 'black', width: '100%', fontSize: 14, marginBottom: 10 }}>
                                                                    {tim.label}
                                                                </Button>
                                                            </Col>



                                                        )}  </Row>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <Button onClick={() => { toggleShowH() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                            close
                                                        </Button>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                        </Col>
                                    </>}
                                </Col>
                            </Row>

                            {excursion?.data.category === 'Cruise' && <>
                                <Row>
                                    <Col md={12}>

                                        <Button onClick={() => { toggleShowE(); setShowB(false); setShowA(false); setShowC(false); setShowD(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', marginTop: 10 }}>
                                            <Row>
                                                <Col md={10}>
                                                    <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Catering</h6>
                                                    <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{catering ? `Yes - ${formatter.format(cateringCost)}` : 'No Catering'}  </p>
                                                </Col>
                                                <Col md={2}>
                                                    <FaAngleDown size={20} color='black' style={{ marginTop: 10 }} />
                                                </Col>
                                            </Row>

                                        </Button>

                                        <Toast show={showE} onClose={toggleShowE} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                            <Toast.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setCatering(false); setCateringCost(0); toggleShowE() }}>No Catering</p>
                                                    </Col>

                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setCatering(true); setCateringCost(excursion?.data.catering); toggleShowE() }}>Yes Catering</p>
                                                    </Col>



                                                </Row>
                                            </Toast.Body>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button onClick={() => { toggleShowE() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                    close
                                                </Button>
                                            </div>
                                        </Toast>
                                    </Col>

                                </Row>



                                <Row>
                                    <Col md={12}>

                                        <Button onClick={() => { toggleShowF(); setShowB(false); setShowA(false); setShowC(false); setShowD(false); setShowE(false) }} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', marginTop: 10 }}>
                                            <Row>
                                                <Col md={10}>
                                                    <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Photography and/or Videography</h6>
                                                    <p style={{ textAlign: 'left', color: 'black', margin: 0, fontSize: 14 }}>{pvText} - {formatter.format(photographyCost + videographyCost)} </p>
                                                </Col>
                                                <Col md={2}>
                                                    <FaAngleDown size={20} color='black' style={{ marginTop: 10 }} />
                                                </Col>
                                            </Row>

                                        </Button>

                                        <Toast show={showF} onClose={toggleShowF} style={{ position: 'absolute', zIndex: '100', backgroundColor: '#fff', border: 'none' }}>

                                            <Toast.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setPVText('No Photography or Videography'); setPhoto(false); setVideo(false); setPhotCost(0); setVideoCost(0); toggleShowF() }}>No Photography or Videography</p>
                                                    </Col>

                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setPVText('Photography Only'); setPhoto(true); setVideo(false); setPhotCost(excursion?.data.photography); setVideoCost(0); toggleShowF() }}>Photography Only</p>
                                                    </Col>


                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setPVText('Videography Only'); setPhoto(false); setVideo(true); setPhotCost(0); setVideoCost(excursion?.data.videography); toggleShowF() }}>Videography Only</p>
                                                    </Col>

                                                    <Col md={12}>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => { setPVText('Photography & Videography'); setPhoto(true); setVideo(true); setPhotCost(excursion?.data.photography); setVideoCost(excursion?.data.videography); toggleShowF() }}>Photography & Videography</p>
                                                    </Col>



                                                </Row>
                                            </Toast.Body>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button onClick={() => { toggleShowF() }} style={{ border: 'none', backgroundColor: 'transparent', color: 'black', right: 0, bottom: 0, position: 'relative' }}>
                                                    close
                                                </Button>
                                            </div>
                                        </Toast>
                                    </Col>

                                </Row>

                            </>
                            }


                            <div style={{ marginTop: 10 }}>
                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>First Name</h6>

                                <input value={firstName} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', padding: '5px', borderRadius: 5 }} onChange={(e) => setFirstName(e.target.value)} />
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Last Name</h6>

                                <input value={lastName} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', padding: '5px', borderRadius: 5 }} onChange={(e) => setLastName(e.target.value)} />
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <h6 className='custom-font' style={{ textAlign: 'left', color: 'black', margin: 0, fontWeight: 'bold', textTransform: 'uppercase', fontSize: 12 }}>Email Address</h6>

                                <input value={email} style={{ backgroundColor: 'transparent', border: '1px solid black', width: '100%', padding: '5px', borderRadius: 5 }} onChange={(e) => setEmail(e.target.value)} />
                            </div>



                            <div style={{ marginTop: 20 }}>

                                <Link style={{ textDecoration: 'none', marginTop: 30 }}

                                    onClick={(e) => {

                                        if (firstName === '' || lastName === '' || email === '') {
                                            return
                                        }

                                        if (!isAuth) {
                                            dispatch(setLoginShow(true))
                                        } else {
                                            // setPayments(true)
                                            createPaymentIntent()
                                            dispatch(setCheckoutDetails({
                                                id:Date.now(),
                                                bookingDate: Timestamp.fromDate(date),
                                                quantity: adults,
                                                excursionName: excursion?.data.name,
                                                excursionId: excursion?.id,
                                                cost: excursion?.data.cost,
                                                category: excursion?.data.category,
                                                hours: 1,
                                                time: time,
                                                pickup: pickup,
                                                pickupCost: location.cost,
                                                pickupTown: location.town,
                                                pickupTime: pickupTime,
                                                pickupDetails: pickupDetails,
                                                photography: photography,
                                                photographyCost: photographyCost,
                                                videography: videography,
                                                videographyCost: videographyCost,
                                                catering: catering,
                                                cateringCost: cateringCost,
                                                email: email,
                                                name: `${firstName} ${lastName}`,
                                                phone: user?.data.phone,
                                                total: (adults * excursion?.data.cost) + (pickup ? location.cost * adults : 0) + cateringCost + videographyCost + photographyCost,
                                                details: excursion?.data.description,
                                                status: 'New',
                                                userId: auth?.currentUser?.uid,
                                                channel: 'website'
                                            }))
                                        }
                                    }}>
                                    <p style={{ width: '100%', backgroundColor: firstName === '' || lastName === '' || email === '' ? 'gray' : Colors.primary, textAlign: 'center', padding: '10px', borderRadius: 10, color: '#fff', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                        {!auth.currentUser ? 'Login to Book' : 'Book Now'}
                                    </p>
                                </Link>
                                <p style={{ textAlign: 'center', fontWeight: 'normal' }}>Once you complete your booking for this excursion, your card will be charged.</p>

                                <div style={{ marginTop: 30 }}>
                                    <h3 className='custom-font'>Total Due: {formatter.format((adults * excursion?.data.cost) + (pickup ? location.cost * adults : 0) + cateringCost + videographyCost + photographyCost)}</h3>
                                </div>

                            </div>
                            {/* <div>
                            <p>Do you want Photography/Vidoegraphy?</p>
                        </div> */}
                        </div>


                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{ borderRadius: 10, overflow: 'hidden' }}>
                           
                                {/* <GoogleMap

                                    mapContainerStyle={containerStyle}
                                    center={{
                                        lat: excursion?.data.location.lat,
                                        lng: excursion?.data.location.long
                                    }}
                                    zoom={14}
                                >

                                    <Marker position={{
                                        lat: excursion?.data.location.lat,
                                        lng: excursion?.data.location.long
                                    }} />
                                </GoogleMap> */}
                            
                        </div>
                    </Col>
                </Row>


                {clientSecret &&
                    <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
                        <CheckoutForm show={showCheckout} handleClose={() => setShowCheckout(false)} items={[{ image: excursion.data.images[0], id: excursion.id, name: excursion.data.name, quantity: adults, cost: (adults * excursion.data.cost) + (pickup ? location.cost * adults : 0) + cateringCost + videographyCost + photographyCost }]} />
                    </Elements>}
            </Container>

        </div>
    )
}

export default SingleExcursion
