import { combineReducers } from "redux"


const selectedExcursionReducer = (excursion = null, action) => {

    if (action.type === 'SELECTED_EXCURSION') {
        return action.payload
    }
    return excursion
}

const getExcursionsReducer = (excursions = [], action) => {
    if (action.type === 'GET_EXCURSIONS') {
        return action.payload
    }
    return excursions
}

const getProductsReducer = (products = [], action) => {
    if (action.type === 'GET_PRODUCTS') {
        return action.payload
    }
    return products
}

const getLocationsReducer = (locations = [], action) => {
    if (action.type === 'GET_LOCATIONS') {
        return action.payload
    }
    return locations

}

const selectedProductReducer = (product = null, action) => {
    if (action.type === 'SELECTED_PRODUCT') {
        return action.payload
    }
    return product
}

const setLoginShowReducer = (show = false, action) => {
    if (action.type === 'SET_LOGIN_SHOW') {
        return action.payload
    }
    return show
}

const setLogoutShowReducer = (show = false, action) => {
    if (action.type === 'SET_LOGOUT_SHOW') {
        return action.payload
    }
    return show
}




const setJoinUsShowReducer = (show = false, action) => {
    if (action.type === 'SET_JOIN_US_SHOW') {
        return action.payload
    }
    return show
}


const setIsAuthReducer = (auth = false, action) => {
    if (action.type === 'SET_IS_AUTH') {
        return action.payload
    }
    return auth
}

const getUserReducer = (user = null, action) => {
    if (action.type === 'GET_USER') {
        return action.payload
    }
    return user
}


const setCartItemsReducer = (items = [], action) => {
    if (action.type === 'GET_CART_ITEMS') {
        return action.payload
    }
    return items
}


const setCheckoutDetailsReducer = (info = null, action) => {
    if (action.type === 'SET_CHECKOUT_DETAILS') {
        return action.payload
    }
    return info
}

const getAddressesReducer = (addresses = [], action) => {
    if (action.type === 'GET_ADDRESSES') {
        return action.payload
    }
    return addresses
}


const getOrdersReducer = (orders = [], action) => {
    if (action.type === 'GET_ORDERS') {
        return action.payload
    }
    return orders
}

const getBookingsReducer = (bookings = [], action) => {
    if (action.type === 'GET_BOOKINGS') {
        return action.payload
    }
    return bookings
}

const setRedirectDataReducer = (resD = {}, action) => {
    if (action.type === 'SET_REDIRECT_DATA') {
        return action.payload
    }
    return resD
}


const setIdReducer = (id = '', action) => {
    if (action.type === 'SET_ID') {
        return action.payload
    }
    return id
}


export default combineReducers({
    selectedExcursion: selectedExcursionReducer,
    excursions: getExcursionsReducer,
    locations: getLocationsReducer,
    selectedProduct: selectedProductReducer,
    products: getProductsReducer,
    showSigin: setLoginShowReducer,
    showSignout: setLogoutShowReducer,
    joinUsShow: setJoinUsShowReducer,
    isAuth: setIsAuthReducer,
    user: getUserReducer,
    cartItems: setCartItemsReducer,
    checkoutDetails: setCheckoutDetailsReducer,
    addresses: getAddressesReducer,
    orders: getOrdersReducer,
    bookings: getBookingsReducer,
    redirectData: setRedirectDataReducer,
    selectedId: setIdReducer,
})
