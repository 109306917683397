import React from 'react'
import { Container } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'


const NotFound = () => {
    return (
        <div style={{ minHeight: '60vh' }}>
            <Container>
                <div style={{ marginTop: '20vh', textAlign: 'center' }}>
                    <FaTimes size={100} color='red' />
                    <h1 style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 30 }}>Page Not Found</h1>
                    <h3 style={{ textAlign: 'center' }}>Please try another page </h3>
                </div>
            </Container>
        </div>
    )
}

export default NotFound
