import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import "intl";


const Orders = () => {
  const orders = useSelector(state => state.orders)
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const formatDate = (date) => {
    const NS_TO_MS_MULTIPLIER = 1 / 1000000
    const SEC_TO_MS_MULTIPLIER = 1000

    const orderDate = date.seconds * SEC_TO_MS_MULTIPLIER + date.nanoseconds * NS_TO_MS_MULTIPLIER
    return new Date(orderDate).toDateString();
  }
  return (
    <div style={{ minHeight: '60vh' }}>
      <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>My Orders</h1>

      <Container>
        <Row style={{ margin: '30px 0' }}>
          {
            orders.map(order =>
              <Col md={6} >
                <div style={{ boxShadow: '2px 0px 10px 0.5px rgba(50, 50, 93, 0.3)', margin: 10, minHeight: 360, borderRadius: 5, padding: 20, overflow: 'scroll' }}>

                  <h3 style={{ fontWeight: 'bold' }}>Order Date: {formatDate(order.data.orderDate)}</h3>
                  <div style={{ height: 200, overflow: 'scroll' }}>
                    {order.data.order.map(item =>
                      <Row >
                        <Col md={2}> <img src={item.data.image} alt="" style={{ width: 80 }} /> </Col>
                        <Col md={6}><h4>{item.data.name}</h4> </Col>
                        <Col md={2}><h4 style={{ textAlign: 'center' }}>{item.data.quantity}</h4> </Col>
                        <Col md={2}><h4 style={{ textAlign: 'right' }}>{formatter.format(item.data.cost)}</h4> </Col>
                      </Row>
                    )}
                  </div>
                  <h5 style={{ textAlign: 'right' }}>SubTotal: {formatter.format(order.data.total - order.data.taxes - order.data.shippingFee)}</h5>
                  <h5 style={{ textAlign: 'right' }}>Taxes: {formatter.format(order.data.taxes)}</h5>
                  <h5 style={{ textAlign: 'right' }}>Shipping: {formatter.format(order.data.shippingFee)}</h5>
                  <h4 style={{ textAlign: 'right', fontWeight: 'bold' }}>Order Total:   {formatter.format(order.data.total)}</h4>
                  <h5 style={{ fontWeight: 'bold' }}>Shipping Address</h5>
                  <p style={{ margin: 2 }}>{order.data.shipping.data.name}</p>
                  <p style={{ margin: 2 }}>{order.data.shipping.data.street}</p>
                  <p style={{ margin: 2 }}>{order.data.shipping.data.city}</p>
                  <p style={{ margin: 2 }}>{order.data.shipping.data.state}</p>
                  <p style={{ margin: 2 }}>{order.data.shipping.data.country} - {order.data.shipping.data.zip}</p>
                  <p style={{ fontSize: 14, marginTop: 20 }}>STATUS: {order.data.status}</p>
                </div>
              </Col>
            )}
        </Row>
      </Container>

    </div>
  )
}

export default Orders
