import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import Colors from "./Colors";
import { Modal, Button, Row, Col } from "react-bootstrap";
import 'intl'
import { Action } from "@remix-run/router";


export default function CheckoutForm({ show, handleClose, items }) {
    const stripe = useStripe();
    const elements = useElements();

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    var total = 0;

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error, success } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page

                return_url: "https://radventure.co/thank-you",

                // return_url: "https://rad-venture.web.app/thank-you",
                // return_url: "http://localhost:44393/thank-you",
            },
        })

        if(success){
            alert(success)
            // onSuccesss()
        }

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }



    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton ><h2>Checkout</h2></Modal.Header>
            <div style={{ padding: 15 }}>
                <div>
                    <Row>
                        <Col md={2}>
                        </Col>
                        <Col md={6}>
                            <p style={{}}>Name</p>
                        </Col>
                        <Col md={2}>
                            Quantity
                        </Col>
                        <Col md={2}>
                            Cost
                        </Col>
                    </Row>
                    {
                    
                        items.map((item, idx) => {
                            total+= item.cost;
                            return (<div key={idx} style={{ marginBottom: 10 }}>

                                <Row>
                                    <Col md={2}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={item.image} style={{ width: 60, borderRadius: 5 }} />
                                        </div>

                                    </Col>
                                    <Col md={6}>
                                        <p style={{}}>{item.name}</p>
                                    </Col>
                                    <Col md={2}>
                                        {item.quantity}
                                    </Col>
                                    <Col md={2}>
                                        {formatter.format(item.cost)}
                                    </Col>
                                </Row>


                            </div>)


                        })
                    }


                </div>

                <div style={{textAlign:'right', fontSize:25}}>Amount Due: {formatter.format(total)}</div>

                <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement id="payment-element" options={paymentElementOptions} />
                    {!isLoading &&
                        <button disabled={isLoading || !stripe || !elements} id="submit" style={{ width: '100%', backgroundColor: Colors.primary, textAlign: 'center', padding: '10px', borderRadius: 30, color: '#fff', fontWeight: 'bold', textTransform: 'uppercase', border: 'none' }}>
                            <span id="button-text">
                                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                            </span>
                        </button>}
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </div>
        </Modal>

    );
}