import { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouterProvider } from "react-router-dom";
import Header from './shared/Header';
import Footer from './shared/Footer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExcursions, getLocations, getProducts, setLoginShow, setIsAuth, getUser, getCartItems, getAddresses, getOrders, getBookings } from './redux/actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./util/firebase";
import logo from './assests/logo.png'
import Form from 'react-bootstrap/Form';
import Colors from './shared/Colors';
import {router} from './util/routes';
import SignoutModal from './shared/SignoutModal';
import JoinUsModal from './shared/JoinUsModal';
import { onAuthStateChanged } from 'firebase/auth';
import 'react-calendar/dist/Calendar.css';

function App() {



  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')




  const handleClose = () => dispatch(setLoginShow(false));



  const handleSignin = () => {
    signInWithEmailAndPassword(auth, email, password).then((auth) => {
      dispatch(getUser(auth.user.uid))
      handleClose()
      dispatch(setIsAuth(true))
    }).catch(error => {
      console.log('Error', error)
    })

  }






  const show = useSelector(state => state.showSigin)
  
 


  const signinModal = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div style={{ height: '70vh', margin: 20, overflow:'scroll' }}>
          <Modal.Title style={{ textTransform: 'uppercase' }}>Sign In</Modal.Title>
          <div style={{ textAlign: 'center' }}>
            <img src={logo} style={{ width: '50%', marginBottom: 40 }} />
          </div>

          <Form.Label htmlFor="inputEmailAddress">Email</Form.Label>
          <Form.Control
            type="text"
            id="inputEmailAddress"
            onChange={(value)=>{setEmail(value.target.value)}}
          />
          <Form.Label htmlFor="inputPassword5">Password</Form.Label>
          <Form.Control
            type="password"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            onChange={(value)=>{setPassword(value.target.value)}}
          />
          <Form.Text id="passwordHelpBlock" muted style={{marginBottom:20}}>
            Your password must be 8-20 characters long, contain letters and numbers,
            and must not contain spaces, special characters, or emoji.
          </Form.Text>
          <div style={{ marginTop:20 }}>
          <Button style={{ width: '45%', marginRight: '2.5%', backgroundColor: Colors.secondary, borderColor: Colors.secondary}}  onClick={handleClose}>CANCEL</Button>
          <Button style={{ width: '45%', marginLeft: '2.5%', backgroundColor: Colors.primary, borderColor: Colors.primary }} variant="success" onClick={handleSignin}>SIGN IN</Button>
          </div>
        </div>

      </Modal>
    )
  }


  useEffect(() => {
    dispatch(getExcursions())
    dispatch(getLocations())
    dispatch(getProducts())
   if(auth.currentUser){
    dispatch(setIsAuth(true))
    dispatch(getUser(auth.currentUser.uid))
    dispatch(getCartItems(auth.currentUser.uid))
    dispatch(getAddresses(auth.currentUser.uid))
    dispatch(getOrders(auth.currentUser.uid))
    dispatch(getBookings(auth.currentUser.uid))
  }
  }, [auth.currentUser])

  

  return (
    
    <div className="App">
       
      <Header />
      {signinModal()}
      <JoinUsModal />
      <SignoutModal/>
      <div style={{ paddingTop: 100 }} >
        <RouterProvider router={router} />
      </div>
      <Footer />
    </div>
   
  );
}

export default App;
