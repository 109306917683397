import React from 'react'
import { Container } from 'react-bootstrap'

const Terms = () => {
  return (
    <div style={{ minHeight: '60vh' }}>
      {/* <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Terms & Condition</h1> */}
      <Container>
        <div style={{ margin: '30px 0' }}>
          <h3 style={{ fontWeight: 'bold' }}>Terms & Condition</h3>
          <h5 style={{ fontWeight: 'bold' }}>effective date: august 8, 2020</h5>

          <p>
            Welcome to the Jamrock Skateboards!  You are reading these Terms because you are using Jamrock skateboard’s website, digital experience, social media platform, mobile app, wearable technology, or one of our other products or services, all of which are part of Jamrock skateboards’s Platform (“Platform”).  You may access the Platform through a computer, mobile phone, tablet, console, or other technology, which we refer to here as a “Device”.  Your carrier's normal rates and fees apply to your Device.
          </p>

          <p style={{ fontWeight: 'bold' }}>
            These term’s  create a legal binding between you and jamrock Skateboards  and its affiliates (which we may refer to as “Jamrock,” “we,” “us,” or “our,”) regarding your use of the platform.
          </p>

          <h5 style={{}}>A few important points:</h5>
          <ul>
            <li>
              <p>
                <strong>Our terms may change</strong> - we may update these terms from time to time. If a material change is made, we will post a notice on the platform or send you a notification. read through any changes, and if you don’t agree to them, please stop using the platform. If you continue to use our platform after we notify you of changes, you will be deemed to have accepted the updated terms.
              </p>
            </li>
            <li>
              <p>
                <strong>Terms of sale</strong> -  by making any purchase with us, you also agree to our terms of sale.
              </p>
            </li>
            <li>
              <p>
                <strong>PRIVACY POLICY</strong> - Our Privacy policy, describes  the collection and use of personal information on the platform and applies to your use of the platform.
              </p>
            </li>
            <li>
              <p>
                <strong>Rules of Eligibility</strong> - you are only eligible to use the platform if you are of legal age in your country or if you have consent from your parent or guardian. there may be certain age restrictions for specific platform services in various countries.
              </p>
            </li>
            <li>
              <p><strong>Rules for registration</strong> - when you register for an account with us, the following rules apply:</p>
              <ul>
                <li>
                  <p>
                    Provide accurate and current registration information
                  </p>
                </li>
                <li>
                  <p>
                    Keep your registration personal. do not register for more than one jamrock account, register a jamrock account on behalf of someone else, or transfer your account.
                  </p>
                </li>
                <li>
                  <p>
                    Keep your user name,  password and other login credentials secure and do not allow anyone else to use your account.
                  </p>
                </li>
                <li>
                  <p>
                    inform jamrock immediately of any unauthorized use of your jamrock account. you are responsible for anything that happens through your jamrock account — with or without your permission. To the maximum extent by applicable law, jamrock is not responsible for any  loss or any activity that results from the unauthorized use of your account.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <h4 style={{ fontWeight: 'bold' }}>OWNERSHIP OF CONTENT</h4>
          <p>
            Except for User Content (defined below), all of the content on our Platform - including text, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, articles, news stories, sketches, animations, stickers, general artwork and other content ("Content") - is owned by jamrock or others we license Content from, and is protected by copyright, trademark, patent and other laws. jamrock reserves all rights not expressly described in these Terms.
          </p>
          <p>
            All trademarks, service marks and trade names (e.g., the Jamrock Skateboards name and the design) are owned, registered and/or licensed by jamrock.  You do not acquire a license or any ownership rights to any trademarks, service marks, or trade names through your access or use of the Platform or Content.
          </p>
          <p>
            You agree not to change or delete any ownership notices from materials downloaded or printed from the Platform.
          </p>
          <p>
            To the extent Jamrock approves the download or use of Content comprised of copyrights or copyrightable works, jamrock grants you a limited, personal, non-transferable, non-sublicensable, and revocable license to access and use such copyrights or copyrightable works solely for their intended purpose and solely for as long as jamrock makes such Content generally available to the public. You do not acquire any ownership rights in the Content (including any trademarks or other intellectual property included in the Content), and all such Content is intended for personal, non-commercial use.  jamrock reserves the right to monitor your use and to alter or revoke this license or your access to the Content at any time and for any reason. jamrock reserves the right to take down any Content in violation of these terms or jamrock’s intellectual property rights. jamrock allowing you this limited use does not constitute a waiver of any of jamrock’s rights to the Content.
          </p>
          <p>
            Outside of the specific usage rights granted to you by jamrock in connection with the Platform, you agree not to use, copy, edit, translate, display, distribute, download, transmit, sell, create derivative works of, or in any way exploit any Content, including User Content (unless it is your own User Content that you legally post on the Platform), without jamrock’s prior written consent. Unauthorized use of the Content may constitute a breach of copyright, trademark or other intellectual property laws and may subject you to criminal or civil charges and penalties.
          </p>
          <h4 style={{ fontWeight: 'bold' }}>PARTNERS ON THE PLATFORM</h4>
          <p>
          From time to time, jamrock may link to or partner with third-party websites, social media platforms, mobile apps, and other products and services (“Third Parties”).  You may be able to connect with these Third Parties through the Platform, but this does not mean jamrock endorses, monitors or has any control over these Third Parties or their activities, which are subject to separate terms of use and privacy policies.  You should carefully review any Third Party’s sites and terms of use and privacy policy.
          <strong> Jamrock is not responsible for the content, policies, or activities of Third Parties and you interact with Third Parties at your own risk.</strong>
          </p>
          <h4 style={{ fontWeight: 'bold' }}>WARRANTY DISCLAIMER.</h4>
          <p>
          The Platform, Content, and the materials and products on this Platform are provided "AS IS.” We aren’t making any promises of any kind, including about the Platform’s accuracy, adequacy, usefulness, reliability or otherwise. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, jamrock IS NOT RESPONSIBLE OR LIABLE FOR ANY USER CONTENT POSTED ON THE PLATFORM. 
          </p>
          <p>
          Jamrock does not guarantee that the Platform will be uninterrupted or error-free, that any defects will be corrected, or that the Platform is free of viruses or anything else harmful.  
          </p>
          <p>
          To the fullest extent permitted by law, jamrock disclaims all warranties, express or implied, regarding the Platform, Content, User Content and any products or services you may obtain or access through the Platform, including, but not limited to, implied warranties of title, merchant ability, fitness for a particular purpose and non-infringement.
          </p>
          <p>
          You are solely responsible for any damage to your Device resulting from accessing the Platform, to the extent applicable law does not provide otherwise.

We hope you enjoy and get the full benefit of the Platform; however, we do not guarantee any results.
          </p>

          <h4 style={{ fontWeight: 'bold' }}>TERMINATION</h4>
          <p>
          Jamrock may terminate or modify any jamrock Platform, member program, product or service at any time without notice.
          </p>
          <p>
          Jamrock may terminate or suspend your account, delete your profile or any of your User Content, and restrict your use of all or any part of the Platform at any time and for any reason, without any liability to jamrock, subject to applicable law.  
          </p>
          <p>
          You understand and agree that some of your User Content, particularly that which is displayed in an activity feed or in other public places on the Platform, may continue to appear publicly even after your account is terminated, subject to your right to have your User Content removed upon request in accordance with applicable law.
          </p>
          <p>
          These Terms remain in effect even after your account is terminated or you have stopped using the Platform.
          </p>

          <h4 style={{ fontWeight: 'bold' }}>INDEMNIFICATION / LIMITATION OF LIABILITY</h4>

          <p>
          We want you to enjoy our Platform, but jamrock must also protect itself from any damages you may cause.
          </p>

          <p>
          You agree to indemnify, defend, and hold harmless jamrock skateboards., its affiliates, officers, directors, employees, agents, licensors and suppliers (the “jamrock parties”) from and against all claims, losses, liabilities, expenses, damages and costs, including, without limitation, attorneys' fees, arising from or relating in any way to your User Content, your use of Content, your use of the Platform, your conduct in connection with the Platform or with other Platform users, or any violation of these Terms of Use, any law or the rights of any third party. You, for yourself and on behalf of your heirs, estate, insurers, successors and assigns, hereby fully and forever release and discharge the jamrock Parties from any and all claims or causes of action you may have for damages relating in any way to your use of the Platform.
          </p>

          <h4 style={{ fontWeight: 'bold' }}>LIMITATION OF LIABILITY</h4>
          <p>
          NONE OF THE jamrock PARTIES WILL BE LIABLE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION FOR ANY LOST PROFITS OR LOST DATA, THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE PLATFORM OR THE PERFORMANCE OF THE PRODUCTS PURCHASED THROUGH THE PLATFORM OR THE CONDUCT OF OTHER PLATFORM USERS (WHETHER ONLINE OR OFFLINE), OR ATTENDANCE AT A jamrock EVENT OR jamrock PARTNER EVENTS, OR ANY USER CONTENT OR ANY OTHER ACTIVITY IN CONNECTION WITH THE USE OF THE PLATFORM, EVEN IF jamrock HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE PLATFORM. YOUR ONLY REMEDY AGAINST jamrock IN CONNECTION WITH ANY DAMAGES ARISING FROM YOUR USE OF THE PLATFORM OR ANY CONTENT IS TO STOP USING THE PLATFORM. IF jamock IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH IS IN ANY WAY CONNECTED WITH YOUR USE OF THE PLATFORM OR ANY CONTENT, jamrock'S LIABILITY SHALL NOT EXCEED US$100.00 OR EURO 100.00 IF YOU LIVE IN EUROPE.  
          </p>
        </div>
      </Container>
    </div>
  )
}

export default Terms
