import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaAddressBook, FaUser, FaReceipt, FaBook, FaCalendar } from 'react-icons/fa'
import atv from '../assests/atv.jpeg'
import { useState } from 'react'
import AddAddressModal from '../shared/AddAddressModal'
import { Link } from 'react-router-dom'
import UpdateUserModal from '../shared/UpdateUserModal'
import Colors from '../shared/Colors'
import { setLoginShow } from '../redux/actions'


const Account = () => {
  const user = useSelector(state => state.user)
  const address = useSelector(state => state.addresses[0])
  const [showAddress, setShowAddress] = useState(false)
  const [showUser, setShowUser] = useState(false)

  const dispatch =useDispatch()
  
  if(user){
    dispatch(setLoginShow(false))
  }


  return (
    <div style={{ minHeight: '80vh' }}>
      {/* <UpdateUserModal show={showUser} close={() => setShowUser(false)} /> */}
      {/* <AddAddressModal show={showAddress} close={() => setShowAddress(false)} /> */}
      <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>My Account</h1>
      <Container style={{ marginTop: 20 }}>
        <Row>
          <Col md={12} style={{ margin: '0 auto' }}>
            <div style={{ border: '1px solid silver', padding: 10, borderRadius: '50%', width: 200, height: 200, margin: '0 auto' }}>
              <div style={{ border: '1px solid silver', borderRadius: '50%', width: 178, height: 178, overflow: 'hidden' }}>
                <img src={atv} alt="" style={{ width: 185 }} />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 30, marginBottom: 30 }}>
          <Col md={3} >
            <Link to={'/my-orders'} style={{ textDecoration: 'none' }}>
              <div style={{ padding: '10px', textAlign: 'center', margin: '0 auto', borderRadius: 10 }}>
                <FaReceipt size={60} color={'silver'} />
                <h4 style={{ fontWeight: 'bold', marginTop: 20, textDecoration: 'none', color: 'black' }}>My Orders</h4>
              </div>
            </Link>
          </Col>
          <Col md={3}>
            <Link onClick={() => setShowUser(true)} style={{ textDecoration: 'none' }}>
              <div style={{ padding: '10px', textAlign: 'center', margin: '0 auto', borderRadius: 10 }}>
                <FaUser size={60} color={'silver'} />
                <h4 style={{ fontWeight: 'bold', marginTop: 20, color: 'black' }}>Personal Details</h4>
              </div>
            </Link>
          </Col>
          <Col md={3}>
            <Link to={'/my-bookings'} style={{ textDecoration: 'none' }}>
              <div style={{ padding: '10px', textAlign: 'center', margin: '0 auto', borderRadius: 10 }}>
                <FaCalendar size={60} color={'silver'} />
                <h4 style={{ fontWeight: 'bold', marginTop: 20, color: 'black' }}>My Bookings</h4>
              </div>
            </Link>
          </Col>
          <Col md={3}>

            <Link to={'/shipping-addresses'} style={{ textDecoration: 'none' }}>
              <div style={{ padding: '10px', textAlign: 'center', margin: '0 auto', borderRadius: 10 }}>
                <FaAddressBook size={60} color={'silver'} />
                <h4 style={{ fontWeight: 'bold', marginTop: 20, color: 'black' }}>Shipping Address</h4>
              </div>
            </Link>
          </Col>
        </Row>
        <Row style={{ marginBottom: 30 }}>
          <Col md={6} style={{ minHeight: 346 }}>
            <div style={{ boxShadow: '2px 0px 10px 0.5px rgba(50, 50, 93, 0.3)', margin: 10, minHeight: 100, borderRadius: 5, padding: 20, minHeight: 346 }}>
              <h4>Personal Details</h4>
              <h6 style={{ fontWeight: 'bold' }}>First Name</h6>
              
              <p>{user.data?.firstName}</p>

              <h6 style={{ fontWeight: 'bold' }}>Last Name</h6>
              <p>{user.data?.lastName}</p>

              <h6 style={{ fontWeight: 'bold' }}>Email Address</h6>
              <p>{user.data?.email}</p>

              <h6 style={{ fontWeight: 'bold' }}>Phone</h6>
              <p>{user.data?.phone}</p>

            </div>
          </Col>

          <Col md={6} style={{}}>
            <div style={{ boxShadow: '2px 0px 10px 0.5px rgba(50, 50, 93, 0.3)', margin: 10, minHeight: 100, borderRadius: 5, padding: 20, minHeight: 346 }}>
              
              {address ?
                <div>
                  <h4>Default Address - {address.data?.name}</h4>
                  <h6 style={{ fontWeight: 'bold' }}>Street Address</h6>
                  <p>{address.data?.street}</p>

                  <h6 style={{ fontWeight: 'bold' }}>Town/city</h6>
                  <p>{address.data?.city}</p>

                  <h6 style={{ fontWeight: 'bold' }}>State/Province</h6>
                  <p>{address.data?.state}</p>

                  <h6 style={{ fontWeight: 'bold' }}>Country - zip</h6>
                  <p>{address.data?.country} - {address.data?.zip}</p>
                </div>
                :
                <div style={{
                  textAlign: 'center',
                  marginTop: 80
                }}>
                  <h4 style={{ fontWeight: 'bold' }}>No Address Found</h4>
                  <Button onClick={() => setShowAddress(true)}>Add Address</Button>
                </div>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div style={{marginBottom:30}} >
              <a href='/view-photos' style={{ backgroundColor: Colors.primary, color: 'white', padding: '10px 20px', marginBottom: 20, textDecoration:'none', fontWeight:'bold', borderRadius:5 }}>
                View My Pictures
              </a>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  )
}

export default Account
