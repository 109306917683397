import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { selectedExcursion } from '../redux/actions';
import { Link } from 'react-router-dom';

function ExcursionCard({ excur }) {
  const [idx, setIdx] = useState(0)
  const dispatch = useDispatch()

  return (
    <div>
      <div style={{ height: 300, backgroundImage: `url(${excur.data.images[idx]})`, backgroundPosition: 'center, center', backgroundSize: 'cover', borderRadius: 10, overflow: 'hidden', marginBottom: 20 }}>
        <div style={{ backgroundColor: 'rgba(0,0,0,.5)', height: 300, padding: 20, position: 'relative' }}>
          {/* <h5 style={{ textTransform: 'uppercase', color: '#fff' }}>{excur.data.category}</h5>
                    <h3 style={{ color: '#fff', fontWeight: 'bold' }}>{excur.data.name}</h3> */}
          <Row style={{ marginTop: 70 }}>
            <Col md={6}>
              {idx > 0 &&
                <FaAngleLeft size={40} color='white' style={{ cursor: 'pointer' }} onClick={() => {
                  setIdx(idx - 1)
                }} />
              }
            </Col>
            <Col md={6} style={{ textAlign: 'right', }}>
              {idx + 1 < excur.data.images.length && <FaAngleRight size={40} color='white' style={{ cursor: 'pointer' }} onClick={() => {
                setIdx(idx + 1)
              }} />}

            </Col>
          </Row>
          <div style={{ position: 'absolute', left: 10, bottom: 10, width: '90%' }}>
            <ReactStars edit={false} count={5} value={4.5} onChange={() => { }} size={25}
              activeColor="#fff"
              isHalf={true} emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
            />



          </div>
        </div>
      </div>
      <h5 style={{ textTransform: 'uppercase' }}>{excur.data.category}</h5>
      <h3 style={{ fontWeight: 'bold' }}>{excur.data.name}</h3>
      <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '3.6em', lineHeight: '1.2em', minHeight:'98px'}}>{excur.data.description}</p>
      <Row>
        <Col md={6}>
          <Link style={{ textDecoration: 'none', color: 'CaptionText', backgroundColor: 'black', color: 'white', fontWeight: 'bold', padding: ' 8px 15px', borderRadius: 30 }} to={`/excursion/${excur.id}`} onClick={() => dispatch(selectedExcursion(excur))}>
            Book Now
          </Link>
        </Col>
        <Col md={6}>
          <h4 style={{ fontWeight: 'bold', textAlign: 'right' }}>Starting from: ${excur.data.cost}</h4>

        </Col>
      </Row>
    </div>
  )
}

export default ExcursionCard
