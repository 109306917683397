import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Colors from '../shared/Colors'
import { useDispatch, useSelector } from 'react-redux';
import "intl";
import { firestore, auth } from '../util/firebase';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, } from 'firebase/firestore';
import { FiPlus, FiMinus } from "react-icons/fi"
import { FaTrash } from "react-icons/fa"

import { getCartItems } from '../redux/actions';

const CartCard = ({ item }) => {


const dispatch = useDispatch()

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const addQuantity = async () => {
    await updateDoc(doc(firestore, 'carts', item.id), {
      quantity: item?.data.quantity + 1
    })

    dispatch(getCartItems(auth.currentUser.uid))
  }


  const removeQuantity = async () => {
    await updateDoc(doc(firestore, 'carts', item.id), {
      quantity: item?.data.quantity - 1
    })
    dispatch(getCartItems(auth.currentUser.uid))
  }

  const removeItem = async () => {
    await deleteDoc(doc(firestore, 'carts', item?.id))
    dispatch(getCartItems(auth.currentUser.uid))
  }




  return (
    <div style={{ margin: '20px 0' }}>
      <Row>
        <Col md={2}>
          <img src={item?.data.image} alt={item?.data.name} style={{ width: 120, borderRadius: 10 }} />
        </Col>
        <Col md={4}>
          <h2>{item?.data.name}</h2>
          <Button style={{ backgroundColor: Colors.secondary, border: 'none', fontWeight: 'bold' }} onClick={() => removeItem()}>Remove Item</Button>
        </Col>
        <Col md={2}>
        <h4 style={{ fontWeight: 'bold', textAlign: 'right' }}>{formatter.format(item.data.cost)}</h4>
        </Col>
        <Col md={2}>
          <div style={{ textAlign: 'right' }}>
            <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => removeQuantity()}>
              <FiMinus size={20} color={'gray'} style={{ marginBottom: 5 }} />
            </Button>
            {item?.data.quantity}
            <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => addQuantity()}>
              <FiPlus size={20} color={'gray'} style={{ marginBottom: 5 }} />
            </Button>
          </div>
        </Col>
        <Col md={2}>
          <h3 style={{ fontWeight: 'bold', textAlign: 'right' }}>{formatter.format(item?.data.cost * item?.data.quantity)}</h3>
        </Col>
      </Row>
    </div>
  )
}

export default CartCard
