import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'intl'
import { setLoginShow, getUser, setCheckoutDetails, setIdn } from '../redux/actions';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { firestore } from '../util/firebase';
import { useDispatch, useSelector } from 'react-redux';
const ThankYou = () => {

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const paymentIntent = queryParams.get('payment_intent');
  const status = queryParams.get('redirect_status');
  const dispatch = useDispatch()
  const data = useSelector(state => state.checkoutDetails)

console.log('Data', data)

  const handleBooking = async () => {
    const col = doc(firestore, 'bookings', `${data.id}`)
    await setDoc(col, {...data, paymentId:paymentIntent}).then(async (doc) => {
      dispatch(setIdn(doc.id))
    })
  }


  useEffect(() => {
  
      handleBooking()
  
      
  }, [])




  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank You for Your Business!</h1>
        <p className="thank-you-message text-center">
          Your payment was successful, and we appreciate your trust in us.
        </p>
        {paymentIntent && (
          <p className="thank-you-details">
            <strong>Payment ID:</strong> {paymentIntent}
          </p>
        )}
        <a href="/" className="thank-you-button">
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default ThankYou;
