import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";
import { selectedExcursion } from '../redux/actions';
import { firestore } from '../util/firebase';
import { collection, getDocs } from 'firebase/firestore'
import { useEffect } from 'react';
import ExcursionCard from '../shared/ExcursionCard';


const Excursions = () => {
    const excursions = useSelector(state => state.excursions)
    const [filter, setFilter] = useState('')



    const dispatch = useDispatch()


    return (<div style={{ minHeight: '60vh' }}>
        {/* <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Adventures</h1> */}
        <Container style={{ marginTop: 50, marginBottom: 50 }}>
            <Row>
                {
                    excursions?.map(excur =>
                        <Col md={4}>
                            <ExcursionCard excur={excur} />
                            
                        </Col>
                    )
                }

            </Row>

        </Container>
    </div>)
}

export default Excursions
