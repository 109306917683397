import { async } from '@firebase/util';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import Colors from '../shared/Colors';
import firebase, { firestore, auth } from '../util/firebase';
import { useDispatch } from 'react-redux';
import { selectedExcursion, selectedProduct, setIsAuth, setLoginShow } from '../redux/actions';
import { Container, Row, Col, Button } from 'react-bootstrap';
import appdownload from '../assests/rad-venture-dl.png'
import appStore from '../assests/app-store.png'
import playStore from '../assests/play-store.png'
import { collection, getDocs, query, where, addDoc, doc, updateDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import 'intl'
import ExcursionCard from '../shared/ExcursionCard';

const Home = () => {


  const excursions = useSelector(state => state.excursions)
  const products = useSelector(state => state.products)
  const [filter, setFilter] = useState('')
  const isAuth = useSelector(state => state.isAuth)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

  const dispatch = useDispatch()


  const addToCart = async (product) => {
    const coll = collection(firestore, 'carts')
    // const q = 
    const prod = await getDocs(query(coll, where('productId', '==', product.id), where('userId', '==', auth.currentUser?.uid)))

    if (prod.docs.length === 0) {
      await addDoc(collection(firestore, 'carts'), {
        productId: product.id,
        image: product.data().images[0],
        name: product.data().name,
        cost: product.data().cost,
        userId: auth.currentUser?.uid,
        quantity: 1
      })
    } else {
      await updateDoc(doc(firestore, 'carts', prod.docs[0].id), {
        quantity: prod.docs[0].data().quantity + 1
      })
    }
  }



  return (
    <div >
      <Carousel style={{}}>
        {excursions.map(excur =>
          <Carousel.Item interval={5000}>

            <div style={{ height: 800, backgroundImage: `url(${excur.data.images[0]})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
              <div style={{ backgroundColor: 'rgba(0,0,0,0.4)', height: 800, }}> </div>
            </div>

            <Carousel.Caption>

              <h1 style={{ textAlign: 'left', fontWeight:'bolder' }}>{excur.data.name} </h1>
              <h3 style={{ textAlign: 'left', fontWeight:'bold' }}>Starting From: {formatter.format(excur.data.cost)}</h3>
              {/* <p>{excur.data().description}</p> */}
              <div style={{ textAlign: 'left' }} >
                <Link className='carousel-link' to={`/excursion/${excur.id}`} onClick={() => dispatch(selectedExcursion(excur))}>Book Now</Link>
              </div>

            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
      {/* <Row>

        <Col md={12} style={{ marginBottom: 20 }}>
          <Container>
            <div style={{ padding: '40px 0' }}>
              <h2 style={{ textAlign: 'center', textTransform: 'uppercase', color: Colors.primary, fontWeight: 'bolder' }}>About Rad Venture</h2>
              <p style={{ textAlign: 'center' }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi quo, quasi atque soluta at quaerat debitis suscipit animi ullam ipsum, deleniti doloremque, error aperiam. Vitae, veniam quod. Doloremque, placeat nostrum!

                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates minima doloribus, magni saepe officia temporibus corporis nobis sequi nam modi quaerat perspiciatis reprehenderit delectus nesciunt fugit fugiat deleniti similique qui. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae reprehenderit odit cum fugiat! Laudantium nam optio suscipit quasi! Dolor ipsam sunt doloremque temporibus deleniti? Quas error debitis nam commodi ipsa.
              </p>
            </div>
          </Container>
        </Col>

      </Row> */}


      <div>
        <Container>
          <Row>
            <Col md={12} >
              <div style={{ padding: '40px 0' }}>
                <h2 style={{ textAlign: 'center', textTransform: 'uppercase', color: Colors.primary, fontWeight: 'bolder' }}>Check Out Rad Venture Excursions</h2>
                {/* <div style={{ textAlign: 'center', marginBottom: 40 }}>
                  <p onClick={() => setFilter('')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === '' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }} >All</p>
                  <p onClick={() => setFilter('clothing')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'clothing' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Clothing</p>
                  <p onClick={() => setFilter('coffee')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'coffee' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Coffee</p>
                  <p onClick={() => setFilter('other')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'other' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Other</p>
                </div> */}
              </div>


            </Col>

          </Row>

          <Row>
            {excursions.map(excur =>
              <Col md={4} style={{ marginBottom: 20 }}>
                {/* <Link style={{ textDecoration: 'none', color: 'CaptionText' }} to={`/excursion/${excur.id}`} onClick={() => dispatch(selectedExcursion(excur))}> */}
                  <ExcursionCard excur={excur} info={false}/>
                  {/* <div style={{ width: '100%', height: 300, backgroundImage: `url(${excur.data.images[0]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: ' center center', borderRadius: 10 }}></div>
                  <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>{excur.data.name}</h4>
                  <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '3.6em', lineHeight: '1.2em' }}>{excur.data.description}</p> */}
                {/* </Link> */}
              </Col>
            )}
          </Row>
        </Container>
      </div>




      <div style={{ backgroundColor: Colors.primary, padding: '30px 0' }}>
        <Container >
          <Row>

            <Col md={6} sm={12} style={{ padding: 10 }}>
              <h3 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: 20, fontFamily: 'Road Rage', color: 'white' }}>Download Our App</h3>
              <p style={{ color: 'white', fontWeight:'bold', fontSize:'1.2em' }}>Discover adventure at your fingertips with the Rad Venture mobile app! Download now to explore a world of thrilling outdoor activities, connect with fellow enthusiasts, and plan your next great adventure with ease. Whether you're into hiking, biking, or extreme sports, Rad Venture has something for everyone. Join our community today and start your journey to unforgettable experiences!</p>
              <Row>
                {/* <Col md={12}>
                  <h3 style={{ textAlign: 'center', marginTop: 80, fontFamily: 'Road Rage', color: 'white' }}>Available on</h3>
                </Col> */}
                <Col sm={12} md={6}>
                  <a style={{ textDecoration: 'none', textAlign: 'center' }} href={'https://apps.apple.com/us/app/rad-venture/id6450128546'} >
                    <img src={appStore} style={{ width: 200, marginTop: -10 }} />
                  </a>
                </Col>

                <Col sm={12} md={6}>
                  <a style={{ textDecoration: 'none', textAlign: 'center' }} href={'https://play.google.com/store/apps/details?id=com.radventureapp'} >
                    <img src={playStore} style={{ width: 250 }} />
                  </a>
                </Col>
              </Row>
            </Col>

            <Col md={6} sm={12} style={{ padding: 10 }}>
              <div style={{ textAlign: 'center' }}>
                <img src={appdownload} style={{ width: 300 }} />
              </div>

            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div style={{ padding: '40px 0' }}>
          <h2 style={{ textAlign: 'center', textTransform: 'uppercase', color: Colors.primary, fontWeight: 'bolder' }}>Shop JamRock</h2>
          <div style={{ textAlign: 'center', marginBottom: 40 }}>
            <p onClick={() => setFilter('')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === '' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }} >All</p>
            <p onClick={() => setFilter('clothing')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'clothing' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Clothing</p>
            <p onClick={() => setFilter('coffee')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'coffee' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Coffee</p>
            <p onClick={() => setFilter('other')} style={{ display: 'inline-block', margin: '0 20px', borderBottomWidth: 2, borderBottomColor: filter === 'other' ? Colors.secondary : 'transparent', borderBottomStyle: 'solid' }}>Other</p>
          </div>

          <Row>
            {products.map(prod =>
              prod.data.category.includes(filter) &&
              <Col md={3} sm={12} style={{ padding: 10 }}>
                <Link style={{ textDecoration: 'none', color: 'CaptionText' }} to={`/product/${prod.id}`} onClick={() => dispatch(selectedProduct(prod))}>
                  <div style={{ overflow: 'hidden', borderWidth: 1, borderColor: 'silver', borderStyle: 'solid', borderRadius: 7, backgroundImage: `url(${prod.data.images[0]})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 300 }}>


                  </div>

                  <p style={{ textTransform: 'uppercase', marginBottom: -5 }}>{prod.data.category}</p>
                  <h4 style={{ fontWeight: '700', marginTop: 5 }}>{prod.data.name}</h4>
                  <p style={{}}>${prod.data.cost}</p>
                </Link>
                <Button onClick={() => { isAuth ? addToCart(prod) : dispatch(setLoginShow(true)) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, border: 'none' }}>Add to Cart</Button>
              </Col>

            )}
          </Row>


        </div>
      </Container>
    </div>
  )
}

export default Home
