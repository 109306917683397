import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, } from 'react-bootstrap'
import 'intl'
import moment from 'moment'

const Bookings = () => {
    const bookings = useSelector(state => state.bookings)
    console.log('Bookings', bookings)
    const formatDate = (date) => {
        const NS_TO_MS_MULTIPLIER = 1 / 1000000
        const SEC_TO_MS_MULTIPLIER = 1000

        const orderDate = date.seconds * SEC_TO_MS_MULTIPLIER + date.nanoseconds * NS_TO_MS_MULTIPLIER
        return new Date(orderDate).toDateString();
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    return (
        <div style={{ minHeight: '60vh' }}>
            <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>My Bookings</h1>
            <Container>
                <Row style={{ margin: '30px 0' }}>
                    {
                        bookings.map(booking =>
                            <Col md={6} >
                                <div style={{ boxShadow: '2px 0px 10px 0.5px rgba(50, 50, 93, 0.3)', margin: 10, minHeight: 360, borderRadius: 5, padding: 20, overflow: 'scroll' }}>
                                    <h5>{booking.data.category}</h5>
                                    <h3 style={{ fontWeight: 'bold' }}>{booking.data.excursionName}</h3>


                                    <h4 >Booking Details</h4>
                                    <Row>
                                        <Col md={8}>
                                            <h5 style={{ fontWeight: 'bold' }}>Name on Booking</h5>
                                            <p>{booking.data.name}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Quantity</h5>
                                            <p>{booking.data.quantity}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Booking Time</h5>
                                            <p>{booking.data.time}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Bookiing Date</h5>
                                            <p>{formatDate(booking.data.bookingDate)}</p>
                                        </Col>
                                        <Col md={4}>
                                        <h5 style={{ fontWeight: 'bold' }}>Pickup?</h5>
                                            <p>{booking.data.pickup ? 'Yes' : 'No'}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Catering?</h5>
                                            <p>{booking.data.catering ? 'Yes' : 'No'}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Photography?</h5>
                                            <p>{booking.data.photography ? 'Yes' : 'No'}</p>

                                            <h5 style={{ fontWeight: 'bold' }}>Videography?</h5>
                                            <p>{booking.data.vidoegraphy ? 'Yes' : 'No'}</p>
                                        </Col>

                                    </Row>




                                    <h3 style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:   {formatter.format(booking.data.total)}</h3>
                                    <h5 style={{ fontWeight: 'bold' }}>{moment(formatDate(booking.data.bookingDate)).from()}</h5>

                                </div>
                            </Col>
                        )}
                </Row>
            </Container>

        </div>
    )
}

export default Bookings
