import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash, FaPlusCircle } from 'react-icons/fa'
import Colors from '../shared/Colors'
import { getAddresses } from '../redux/actions'
import { firestore, auth } from '../util/firebase'
import {doc, deleteDoc } from 'firebase/firestore'
import AddAddressModal from '../shared/AddAddressModal'
import { useState } from 'react'

const ShippingAddresses = () => {
    const addresses = useSelector(state => state.addresses)
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const deleteAddress = async(id) =>{
        await deleteDoc(doc(firestore, 'addresses', id))
        dispatch(getAddresses(auth.currentUser.uid))
    }
    return (
        <div style={{ minHeight: '60vh' }}>
            <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>SHipping Addresses</h1>
            <Container> 
                <AddAddressModal show={show} close={()=>setShow(false)} />
                <div style={{textAlign:'right'}}>
                <Button style={{backgorundColor:Colors.primary}} onClick={()=>setShow(true)}>Add New Address</Button>
                </div>
                 <Row style={{margin:'30px 0'}}>
                    {addresses.map((address) =>
                        <Col md={6} style={{}}>
                            <div style={{ boxShadow: '2px 0px 10px 0.5px rgba(50, 50, 93, 0.3)', margin: 10, minHeight: 100, borderRadius: 5, padding: 20, minHeight: 346 }}>
                                <h4>{address.data.name}</h4>
                               
                                    <div>
                                        <h6 style={{ fontWeight: 'bold' }}>Street Address</h6>
                                        <p>{address.data.street}</p>

                                        <h6 style={{ fontWeight: 'bold' }}>Town/city</h6>
                                        <p>{address.data.city}</p>

                                        <h6 style={{ fontWeight: 'bold' }}>State/Province</h6>
                                        <p>{address.data.state}</p>

                                        <h6 style={{ fontWeight: 'bold' }}>Country - zip</h6>
                                        <p>{address.data.country} - {address.data.zip}</p>
                                    </div>
                                    <div style={{textAlign:'right', cursor:'pointer'}}>
                                    <FaTrash size={20} color={Colors.secondary} onClick={()=>deleteAddress(address.id)}/>
                                    </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>

        </div>
    )
}

export default ShippingAddresses
