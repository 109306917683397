import React, { useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaTimes, FaDownload } from 'react-icons/fa'

const PhotoModal = ({ images, display, close, idx, next, previous, save }) => {



  return (
    <div style={{ width: '100vw', height: '100vh', zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'fixed', top: 0, padding: 50, display: display }}>
      <FaTimes size={30} color='white' style={{ position: 'absolute', right: 20, cursor: 'pointer' }} onClick={() => close()} />
      <FaAngleLeft size={50} color='white' style={{ position: 'absolute', left: 20, top: '50%', cursor: 'pointer' }} onClick={() => previous(idx)} />
      <div style={{ width: '100%', height: '100%', borderRadius: 10, textAlign: 'center', overflow: 'hidden' }}>
        <img src={images[idx]?.url} alt="" style={{ height: '100%', borderRadius: 10, }} />

      </div>
      <FaAngleRight size={50} color='white' style={{ position: 'absolute', right: 20, top: '50%', cursor: 'pointer' }} onClick={() => next(idx)} />
      <FaDownload size={30} color='white' style={{ position: 'absolute', right: 40, bottom: 40, cursor: 'pointer' }} onClick={() => save(images[idx]?.imageRef, idx)} />

    </div>
  )
}

export default PhotoModal
