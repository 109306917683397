import React from 'react'

const Cancellation = () => {
  return (
    <div style={{minHeight:'60vh'}}>
            <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Cancellation Policy</h1>
      
    </div>
  )
}

export default Cancellation
