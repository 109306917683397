import React from 'react'
import { Container } from 'react-bootstrap'

const Refund = () => {
  return (
    <div style={{ minHeight: '60vh' }}>
      {/* <h1 style={{ textAlign: 'center', padding: '50px 0', color: '', fontWeight: 'bold' }}>Return Policy</h1> */}
      <Container>
        <div style={{ margin: '50px 0' }}>
          <h3 style={{ fontWeight: 'bold' }}>Return Policy</h3>
          <h5 style={{ fontWeight: 'bold' }}>effective date: august 8, 2020</h5>

          <h4 style={{ fontWeight: 'bold', marginTop:20 }}>Damaged or Defective items</h4>
          <p>
            Any claims for damaged/defective items must be submitted within 3 weeks after the product has been received. For packages lost in transit, all claims must be submitted no later than 3 weeks after the estimated delivery date. Claims deemed an error on our part are covered at our expense.
          </p>

          <h4 style={{ fontWeight: 'bold' }}>Wrong Address</h4>

          <p>
            If you provide an address that is considered insufficient by the courier, the shipment will be returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable).
          </p>
          <h4 style={{ fontWeight: 'bold' }}>Unclaimed</h4>

         <p> Shipments that go unclaimed are returned to our facility and you will be liable for the cost of a reshipment to yourself (if and as applicable).

          Jamrock skateboards does not accept returns of sealed goods, such as but not limited to face masks, which are not suitable for return due to health or hygiene reasons. You hereby agree that any returned orders with face masks won’t be available for reshipping and will be disposed of.

          We do not refund orders for buyer’s remorse. Returns for products, face masks, as well as size exchanges are offered at your expense. If you choose to return a product for  size exchanges, you would need to place a new order at your expense for a face mask or a product in another size.</p>

          <h4 style={{ fontWeight: 'bold' }}>HOW DO I REPORT A PROBLEM WITH MY ORDER?</h4>

          <p>Write up your problem in detail and upload relevant photos where possible</p>

          <p>Send to @jamrockskateboards@gmail.com</p>

          <p>Be aware that JAMROCK skateboards may require a photo to be submitted with your problem report to begin a reshipment. It is always best to include photos with your initial problem report to avoid delays.</p>
        </div>
      </Container>
    </div>
  )
}

export default Refund
