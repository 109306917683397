import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, } from 'firebase/firestore';
import { auth, firestore, storage } from '../util/firebase';
import FileSaver from 'file-saver'
import { FaEye, FaAngleLeft, FaAngleRight, FaDownload } from 'react-icons/fa';
import PhotoModal from '../shared/PhotoModal';
import { getStorage, ref, getDownloadURL, getBlob } from "firebase/storage";

const ViewPhotos = () => {


    const isAuth = useSelector(s => s.isAuth)
    const user = useSelector(s => s.user)
    const navigate = useNavigate()
    const [photos, setPhotos] = useState([])
    const [display, setDisplay] = useState('none')

    const [images, setImages] = useState([])
    const [idx, setIdx] = useState(0)


    if (!isAuth) {
        navigate('/')
    }


    const getMyPhotos = async () => {
        const col = collection(firestore, 'photos')
        const q = query(col, where('uid', '==', user.id))

        const data = await getDocs(q)
        const temp = []


        if ((await data).docs.length > 0) {
            await data.forEach(doc => {
                temp.push({ id: doc.id, data: doc.data() })
            })
        }
        setPhotos(temp)
    }

    useEffect(() => {
        getMyPhotos()

    }, [])


    const next = (idx) => {
        if (idx === images.length - 1) {
            setIdx(0)
        } else {
            setIdx(idx + 1)
        }
    }

    const previous = (idx) => {
        if (idx === 0) {
            setIdx(images.length - 1)
        } else {
            setIdx(idx - 1)
        }
    }


    const saveFile = async (imageRef, idx) => {
        await getBlob(ref(storage, imageRef), 1000000000)
            .then((blob) => {

                FileSaver.saveAs(blob, `${user.data.firstName}-${idx}.jpg`)


            })
            .catch((error) => {
                console.log('object', error)
                // Handle any errors
            });
    }


    if (photos.length === 0) {
        return (
            <div style={{ minHeight: '60vh' }}>
                <Container>

                    <Row >
                        <Col md={12}>
                            <h1><b>{user.data?.firstName} {user.data?.lastName}'s photos</b></h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 200 }}>There are no images to view</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <div style={{ minHeight: '80vh' }}>
            <PhotoModal images={images} display={display} close={() => setDisplay('none')} idx={idx} next={next} previous={previous} save={saveFile} />
            <Container>

                <Row>
                    <Col md={12}>
                        <h1><b>{user.data?.firstName} {user.data?.lastName}'s photos</b></h1>
                    </Col>
                </Row>


                {
                    photos.map((photo, idx) => {
                        return (<div key={idx}>
                            <h3 key={idx} style={{ fontWeight: 'bold' }}>{moment(photo.data.date.toDate()).format('ddd MMM DD, yyyy').toString()}</h3>
                            <Row>{
                                photo.data.images.map((pic, idx) =>
                                    <Col key={idx} md={3} style={{ marginBottom: 25 }}>
                                        <div key={idx} 
                                        onClick={() => {
                                            setDisplay('block')
                                            setImages(photo.data.images)
                                            setIdx(idx)
                                        }}
                                        style={{
                                            width: '100%', height: 200, backgroundColor: 'black', borderRadius: 10, padding: 20,
                                            backgroundImage: `url(${pic.url})`, backgroundPosition: 'center center', backgroundSize: 'cover', cursor: 'pointer'
                                        }}>

                                            
                                        </div>
                                        <div key={idx} style={{ textAlign:'right' }}>


                                                <FaEye size={20} color='grey' onClick={() => {
                                                    setDisplay('block')
                                                    setImages(photo.data.images)
                                                    setIdx(idx)
                                                }}
                                                    style={{ cursor: 'pointer', marginRight: 20 }} />


                                                <FaDownload color='grey' onClick={() =>
                                                    saveFile(pic.imageRef, idx) // Put your image URL here.
                                                }
                                                    style={{ cursor: 'pointer', marginRight: 10 }} />

                                            </div>
                                    </Col>)
                            }</Row>
                        </div>)
                    }

                    )
                }
                {/* <Button onClick={removeImages}>Delete All</Button> */}
            </Container>

        </div>
    )
}

export default ViewPhotos
