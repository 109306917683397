import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Modal, Button } from 'react-bootstrap';
import { setLogoutShow, setIsAuth } from '../redux/actions';
import { signOut } from 'firebase/auth';
import { auth } from "../util/firebase";
import logo from '../assests/logo.png'
import Colors from '../shared/Colors';



const SignoutModal = () => {


    const dispatch = useDispatch()
    const signout = useSelector(state => state.showSignout)

    const close = () => dispatch(setLogoutShow(false));


    const handleSignOut = () => {
        signOut(auth).then(() => {
          close()
          window.location.href='/'
          dispatch(setIsAuth(false))
        }).catch((err) => {
    
        })
      }


  return (
    <Modal
        show={signout}
        onHide={close}
        backdrop="static"
        keyboard={false}
      >
        <div style={{ margin: 20 }}>
          <div style={{ textAlign: 'center' }}>
            <img src={logo} style={{ width: '50%', marginBottom: 40 }} />
          </div>
          <h4 style={{ textAlign: 'center', marginBottom: 30 }}>Are You Sure You Want To Sign Out?</h4>

          <Button  style={{ width: '45%', marginRight: '2.5%', backgroundColor: Colors.secondary, borderColor: Colors.secondary }} onClick={() => close()}>CANCEL</Button>
          <Button  style={{ width: '45%', marginLeft: '2.5%', backgroundColor: Colors.primary, borderColor: Colors.primary  }} onClick={handleSignOut}>SIGN OUT</Button>
        </div>

      </Modal>  
  )
}

export default SignoutModal
