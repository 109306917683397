import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setJoinUsShow, setIsAuth, getAddresses } from '../redux/actions'
import logo from '../assests/logo.png'
import Colors from '../shared/Colors';
import { auth, firestore } from '../util/firebase'

import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { doc, addDoc, collection } from 'firebase/firestore'
const AddAddressModal = ({ show, close }) => {

    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [street, setStreet] = useState('')
    const [zip, setZip] = useState('')

    const [message, setMessage] = useState('')



    const addAddress = async () => {
        await addDoc(collection(firestore, 'addresses'), {
            city,
            country,
            name: `${firstName} ${lastName}`,
            phone,
            state,
            street,
            userId: auth.currentUser.uid,
            zip
        }).then(() => {
            dispatch(getAddresses(auth.currentUser.uid))
            close()
        })

    }

    return (
        <div>
            <Modal
                show={show}
                onHide={close}
                backdrop="static"
                keyboard={false}

            >
                <div style={{ margin: 20, overflow: 'scroll' }}>
                    <Modal.Title style={{ textTransform: 'uppercase' }}>Create Shipping Address</Modal.Title>
                    {/* <div style={{ textAlign: 'center' }}>
                        <img src={logo} style={{ width: '30%', marginBottom: 40 }} />
                    </div> */}

                    <p style={{ color: 'red' }}>{message}</p>

                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="firstName"
                        onChange={(value) => { setFirstName(value.target.value); }}
                    />

                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="lastName"
                        onChange={(value) => { setLastName(value.target.value); }}
                    />
                    <Form.Label htmlFor="phoneNumber">Phone</Form.Label>
                    <Form.Control
                        type="text"
                        id="phoneNumber"
                        onChange={(value) => { setPhone(value.target.value); }}
                    />

                    <Form.Label htmlFor="street">Street</Form.Label>
                    <Form.Control
                        type="text"
                        id="street"
                        onChange={(value) => { setStreet(value.target.value); }}
                    />
                    <Form.Label htmlFor="City">City</Form.Label>
                    <Form.Control
                        type="text"
                        id="city"
                        aria-describedby="city"
                        onChange={(value) => { setCity(value.target.value); }}
                    />

<Form.Label htmlFor="state">State</Form.Label>
                    <Form.Control
                        type="text"
                        id="state"
                        aria-describedby="state"
                        onChange={(value) => { setState(value.target.value); }}
                    />
                    <Form.Label htmlFor="country">Country</Form.Label>
                    <Form.Control
                        type="text"
                        id="country"
                        aria-describedby="counrty"
                        onChange={(value) => { setCountry(value.target.value); }}
                    />

                    <Form.Label htmlFor="country">Zip</Form.Label>
                    <Form.Control
                        type="text"
                        id="country"
                        aria-describedby="counrty"
                        onChange={(value) => { setZip(value.target.value); }}
                    />
                    <div style={{ marginTop: 20 }}>
                        <Button style={{ width: '45%', marginRight: '2.5%', backgroundColor: Colors.secondary, borderColor: Colors.secondary }} onClick={() => close()}>CANCEL</Button>
                        <Button style={{ width: '45%', marginLeft: '2.5%', backgroundColor: Colors.primary, borderColor: Colors.primary }} variant="success" onClick={() => addAddress()}>Add Shipping Address</Button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default AddAddressModal
