import React from 'react'
import foundation from '../assests/jmfoundation-white.png'
const Foundation = () => {
  return (
    <div style={{ minHeight: '60vh' }}>
      {/* <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Foundation</h1> */}
      <div style={{textAlign:'center', marginTop:50}}>
        <img src={foundation} style={{ width: 300, backgroundColor:'rgba(0, 0, 0, 1)', boxShadow: '0px 0px 5px 10px rgba(0, 0, 0, 1)' }} />
        <h1 style={{ textAlign: 'center', padding: '50px 0', fontWeight: 'bold' }}>Coming soon</h1>
      </div>
    </div>
  )
}

export default Foundation
