import firebase, { firestore } from '../util/firebase';
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { async } from '@firebase/util';

export const getUser = (uid) => async dispatch => {

    const coll = collection(firestore, 'users')
    const q = query(coll, where('userId', '==', uid))
    onSnapshot(q, (querySnapshot) => {
        dispatch({
            type: 'GET_USER',
            payload: {id:querySnapshot.docs[0].id, data:querySnapshot.docs[0].data()}
        })
    })
}

export const getCartItems = (uid) => async dispatch => {
    const coll = collection(firestore, 'carts')
    const q = query(coll, where('userId', '==', uid))


    // const data = await getDocs(q)

    onSnapshot(q, (querySnapshot) => {
        
        let data = []
        querySnapshot.docs.map(itm=>{
            data.push({id:itm.id, data:itm.data()})
        })
        dispatch({
            type: 'GET_CART_ITEMS',
            payload: data
        })
    })

    
}


export const selectedExcursion = (excursion) => {
    return {
        type: 'SELECTED_EXCURSION',
        payload: excursion
    }
}

export const selectedProduct = (product) => {
    return {
        type: 'SELECTED_PRODUCT',
        payload: product
    }
}

export const getExcursions = () => async dispatch => {
    const productsCol = collection(firestore, 'excursions')
    const productsSnap = await getDocs(productsCol)

    const data = []
    productsSnap.docs.forEach((item)=>{
        data.push({id:item.id, data:item.data()})
    })
    dispatch({
        type: 'GET_EXCURSIONS',
        payload: data
    })
}

export const getProducts = () => async dispatch => {
    const productsCol = collection(firestore, 'products')
    const productsSnap = await getDocs(productsCol)
    

    const data = []
    productsSnap.docs.forEach((item)=>{
        data.push({id:item.id, data:item.data()})
    })


    dispatch({
        type: 'GET_PRODUCTS',
        payload: data
    })

}
export const getLocations = () => async dispatch => {
    const productsCol = collection(firestore, 'pickupLocations')
    const productsSnap = await getDocs(productsCol)

    let data = []
    productsSnap.docs.map(loca=>{
        data.push({id:loca.id, data:loca.data()})
    })
    dispatch({
        type: 'GET_LOCATIONS',
        payload: data
    })

}

export const setLoginShow = (bool) => dispatch => {

    dispatch({
        type: 'SET_LOGIN_SHOW',
        payload: bool
    })
}

export const setLogoutShow = (bool) => dispatch => {

    dispatch({
        type: 'SET_LOGOUT_SHOW',
        payload: bool
    })
}

export const setJoinUsShow = (bool) => dispatch => {

    dispatch({
        type: 'SET_JOIN_US_SHOW',
        payload: bool
    })
}


export const setIsAuth = (bool) => dispatch => {

    dispatch({
        type: 'SET_IS_AUTH',
        payload: bool
    })
}

export const getAddresses = (uid) =>async dispatch=>{
    const coll = collection(firestore, 'addresses')
    const q = query(coll, where('userId', '==', uid))
    onSnapshot(q, (querySnapshot) => {
        let data = []
        querySnapshot.docs.map(itm=>{
            data.push({id:itm.id, data:itm.data()})
        })
    dispatch({
        type:'GET_ADDRESSES',
        payload: data
    })
})

}


export const getOrders = (uid) =>async dispatch=>{
    const coll = collection(firestore, 'orders')
    const q = query(coll, where('userId', '==', uid))
    onSnapshot(q, (querySnapshot) => {
        let data = []
        querySnapshot.docs.map(itm=>{
            data.push({id:itm.id, data:itm.data()})
        })
    dispatch({
        type:'GET_ORDERS',
        payload: data
    })
})

}


export const getBookings = (uid) =>async dispatch=>{
    const coll = collection(firestore, 'bookings')
    const q = query(coll, where('userId', '==', uid))
    onSnapshot(q, (querySnapshot) => {
        let data = []
        querySnapshot.docs.map(itm=>{
            data.push({id:itm.id, data:itm.data()})
        })
    dispatch({
        type:'GET_BOOKINGS',
        payload: data
    })
})

}


export const setCheckoutDetails = (info) => dispatch=>{
    dispatch({
        type: 'SET_CHECKOUT_DETAILS',
        payload: info
    })

}


export const setRedirectData = (data) =>{
    return{
        type:'SET_REDIRECT_DATA',
        payload: data
    }
}

export const setIdn=(id) =>{
    return{
        type:'SET_ID',
        payload: id
    }
}