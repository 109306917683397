import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import jamrock from '../assests/JAMROCKnc_1.PNG'
import { Link } from 'react-router-dom'
import { selectedProduct, setLoginShow } from '../redux/actions'
import firebase, { firestore, auth } from '../util/firebase';
import { collection, getDocs, query, where, addDoc, doc, updateDoc } from 'firebase/firestore';
import Colors from '../shared/Colors'
import { getCartItems } from '../redux/actions'

const Shop = () => {

    const products = useSelector(state => state.products)

    // const [products, setProducts] = useState([])
    const isAuth = useSelector(state => state.isAuth)

    const [filter, setFilter] = useState('')



    const dispatch = useDispatch()

    const addToCart = async (product) => {
        const coll = collection(firestore, 'carts')
        // const q = 
        const prod = await getDocs(query(coll, where('productId', '==', product.id), where('userId', '==', auth.currentUser?.uid)))

        if (prod.docs.length === 0) {
            await addDoc(collection(firestore, 'carts'), {
                productId: product.id,
                image: product.data.images[0],
                name: product.data.name,
                cost: product.data.cost,
                userId: auth.currentUser?.uid,
                quantity: 1
            })
        } else {
            await updateDoc(doc(firestore, 'carts', prod.docs[0].id), {
                quantity: prod.docs[0].data().quantity + 1
            })
        }

dispatch(getCartItems(auth.currentUser?.uid))
    }



    // useEffect(() => {
    //     getProducts()
    // })

    
    return (
        <div style={{ minHeight: '60vh' }}>
            {/* <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Jamrock</h1> */}
            <Container>
                <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
                    <img src={jamrock} style={{ width: 150 }} />
                </div>

                <Row>
                    <Col md={3}></Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>all</h6>
                    </Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('coffee')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>coffee</h6>
                    </Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('men')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>Men</h6>
                    </Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('women')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>Women</h6>
                    </Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('kids')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>kids</h6>
                    </Col>
                    <Col md={1}>
                        <h6 onClick={() => setFilter('accessories')} style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em', cursor:'pointer' }}>accessories</h6>
                    </Col>
                    <Col md={3}></Col>
                </Row>

            </Container>

            <Row style={{ margin: 30,}}>
                {products.map(prod =>
                 prod.data.subCat.includes(filter) &&
                    <Col key={prod.id} md={2}>
                        <Link style={{ textDecoration: 'none', color: 'CaptionText' }} to={`/product/${prod.id}`} onClick={() => dispatch(selectedProduct(prod))}>
                            <img src={prod.data.images[0]} style={{ width: '100%', height: 254, backgroundColor: 'silver', borderRadius:10 }} />
                        </Link>
                        <Button onClick={() => { isAuth ? addToCart(prod) : dispatch(setLoginShow(true)) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, border: 'none', marginTop:10 }}>Add to Cart</Button>

                    </Col>
                    
                )}

            </Row>
        </div>
    )
}

export default Shop
