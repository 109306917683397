import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Colors from '../shared/Colors'
import { useDispatch, useSelector } from 'react-redux';
import "intl";
import { firestore, auth } from '../util/firebase';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, } from 'firebase/firestore';
import CartCard from '../shared/CartCard';
import { FaShoppingCart } from "react-icons/fa"
import { Link } from 'react-router-dom';
import { getCartItems, setCheckoutDetails } from '../redux/actions';
import CheckoutForm from '../shared/CheckoutForm';
import Form from 'react-bootstrap/Form';
import AddAddressModal from '../shared/AddAddressModal';

const Carts = () => {

    const items = useSelector((state) => state.cartItems)
    const dispatch = useDispatch()
    // const [items, setItems] = useState([])
    const addresses = useSelector((state) => state.addresses)
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState(50)
    const [showCheckout, setShowCheckout] = useState(false)
    const [address, setAddress] = useState(addresses[0])
    const [index, setIndex] = useState(0)

    const [show, setShow] = useState(false)



    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })





    const calculateTotal = () => {
        let tot = 0
        items.map((item) => {
            tot += item?.data.quantity * item?.data.cost
        })
        return tot
    }

    const calculateTaxes = () => {
        let tot = 0
        items.map((item) => {
            tot += item?.data.quantity * item?.data.cost
        })
        return (tot * 0.165)
    }






    const handleOrder = async () => {
        const coll = collection(firestore, 'orders')

        await addDoc(coll, {
            userId: auth.currentUser?.uid,
            order: items,
            shipping: address,
            orderDate: Timestamp.fromDate(new Date()),
            shippingFee: shipping,
            taxes: calculateTaxes(),
            total: calculateTotal(),
            status: 'New'
        }).then(() => {
            items?.forEach(async (item) => {
                const coll = collection(firestore, 'carts')
                await deleteDoc(doc(firestore, 'carts', item?.id))
                const coll1 = collection(firestore, 'products')

                const prod = getDocs(coll1, item?.data.productId)

                await updateDoc(doc(firestore, 'products', item?.data.productId), {
                    sold: parseInt(prod?.data().sold) + parseInt(item?.data.quantity)
                })
                window.location.href = '/thank-you'
            })
        })
    }


    if (items.length === 0) {
        return (
            <div style={{ minHeight: '60vh' }}>
                <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Cart</h1>
                <div style={{ padding: '20vh 0', textAlign: 'center' }}>
                    <FaShoppingCart size={120} color={'gray'} style={{ marginBottom: 10 }} />
                    <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Your cart is currently empty</h2>
                    <Link to='/store' style={{ backgroundColor: Colors.primary, padding: 10, borderRadius: 5, textDecoration: 'none', color: 'white', fontWeight: 'bold' }}>Return to Store</Link>
                </div>
            </div>
        )
    }

    const genItem = () => {
        let data = []
        items.map((item) => {
            const total = item.data.cost * item.data.quantity
            const tax = (item.data.cost * item.data.quantity) * 0.165
            const ship = shipping / items.length
            data.push({ image: item.data.image, id: item.id, name: item.data.name, quantity: item.data.quantity, cost: total + ship + tax })
        })

        return data

    }




    return (
        <div style={{ minHeight: '60vh', }}>
            <h1 style={{ textAlign: 'center', padding: '50px 0', backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>Cart</h1>
            <Container style={{ minHeight: '40vh', padding: '50px 0' }}>
                {items.map(item =>
                    <CartCard item={item} />
                )
                }

                <h3 style={{ textAlign: 'right' }}>SubTotal:   {formatter.format(calculateTotal())}</h3>
                <h3 style={{ textAlign: 'right' }}>Taxes:   {formatter.format(calculateTaxes())}</h3>

                <h3 style={{ textAlign: 'right' }}>Shipping:   {formatter.format(shipping)}</h3>


                <h2 style={{ textAlign: 'right', fontWeight: 'bold' }}>Order Total:   {formatter.format(calculateTotal() + shipping + calculateTaxes())}</h2>


                <h3 style={{ fontWeight: 'bold' }}>Select Shipping Address</h3>
                {addresses.length > 0 ?
                    addresses.map((address, idx) => <>

                        <div key={`address`} className="mb-3">
                            <Form.Check
                                type={'radio'}
                                name="group1"
                                checked={index === idx}
                                isValid={index === idx}
                                onClick={() => { setIndex(idx); setAddress(address.data) }}
                                id={`address-${idx}`}
                                label={`${address.data.name}, ${address.data.street}, ${address.data.city}, ${address.data.country}, ${address.data.zip}`}
                            />


                        </div>
                    </>)
                    : <div>
                        <h5 style={{ fontWeight: 'bold' }}>No Shipping Address </h5>
                        <Button style={{ border: 'none', backgroundColor: Colors.primary, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 30 }}
                            onClick={() => {
                                setShow(true)

                            }}>Add Shipping Address</Button>
                    </div>
                }



                <Button disabled={!address} style={{ width: 500, border: 'none', backgroundColor: Colors.secondary, fontWeight: 'bold', textTransform: 'uppercase' }}
                    onClick={() => {
                        // handleOrder()
                        setShowCheckout(true)
                        dispatch(setCheckoutDetails({
                            type: 'cart', data: {
                                userId: auth.currentUser?.uid,
                                order: items,
                                shipping: address,
                                orderDate: new Date(),
                                shippingFee: shipping,
                                taxes: calculateTaxes(),
                                total: parseFloat(calculateTotal() + calculateTaxes() + shipping),
                                status: 'New'
                            }
                        }))
                    }}>Checkout</Button>
                <AddAddressModal show={show} close={() => setShow(false)} />
            </Container>
        </div>
    )
}

export default Carts
