import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ReactStars from "react-rating-stars-component";
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jamrock from '../assests/JAMROCKnc_1.PNG'
import { auth, firestore } from '../util/firebase';
import { FiPlus, FiMinus } from "react-icons/fi"
import { collection, getDocs, query, where, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import Colors from '../shared/Colors';
import { setLoginShow } from '../redux/actions';
import { getCartItems } from '../redux/actions';
import { async } from '@firebase/util';

const SingleProduct = () => {

    const [product, setProduct] = useState(useSelector(state => state.selectedProduct) || null)
    const [quantity, setQuantity] = useState(1)
    const isAuth = useSelector(state => state.isAuth)
    const dispatch = useDispatch()

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })


    const getProd = async () => {
        const prod = await getDoc(doc(firestore, 'products', window.location.pathname.substring(50, 9)))
        setProduct({id:prod.id, data:prod.data()})
    }

    const addToCart = async () => {
        const coll = collection(firestore, 'carts')
        // const q = 
        const prod = await getDocs(query(coll, where('productId', '==', product.id), where('userId', '==', auth.currentUser?.uid)))

        if (prod.docs.length === 0) {
            await addDoc(collection(firestore, 'carts'), {
                productId: product.id,
                image: product.data.images[0],
                name: product.data.name,
                cost: product.data.cost,
                userId: auth.currentUser?.uid,
                quantity: quantity
            })
        } else {
            await updateDoc(doc(firestore, 'carts', prod.docs[0].id), {
                quantity: prod.docs[0].data().quantity + quantity
            })
        }
        dispatch(getCartItems(auth.currentUser?.uid))
    }

    if (!product) {
        getProd()
        return (<div style={{minHeight:'60vh'}}></div>)
    }

    return (
        <div>
            <h6 style={{ marginLeft: 30, marginTop: 20, fontWeight: 'bold' }}><Link to={'/'}>Home</Link> / <Link to={'store'}>Store</Link> / <Link style={{ textTransform: 'capitalize' }}>{product.data.category}</Link> / <Link style={{ textTransform: 'capitalize' }}>{product.data.subCat}</Link> / {product.data.name}</h6>
            <div style={{}}>

                <Container>

                    <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
                        <img src={jamrock} style={{ width: 150 }} />
                    </div>


                    <Row style={{ marginBottom: 30 }}>
                        <Col md={6}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={product.data.images[0]} style={{ width: '90%' }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <h3 style={{ textAlign: 'center' }}>{product.data.name}</h3>
                            <p style={{ textAlign: 'center' }}>{product.data.description}</p>
                            <h4 style={{ textAlign: 'right' }}>{formatter.format(product.data.cost)}<span style={{ fontSize: 12 }}> US</span></h4>
                            <h6>Amount sold: {product.data.sold}</h6>
                            <p>Quantity</p>
                            <div style={{}}>
                                <Row>
                                    <Col md={6}>
                                        {/* <p style={{ textAlign: 'right' }}>Person(s)</p> */}
                                    </Col>
                                    <Col md={6}>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { if (quantity > 1) { setQuantity(quantity - 1) } }}>
                                                <FiMinus size={20} color={'gray'} style={{ marginBottom: 5 }} />
                                            </Button>
                                            {quantity}
                                            <Button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { setQuantity(quantity + 1) }}>
                                                <FiPlus size={20} color={'gray'} style={{ marginBottom: 5 }} />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <ReactStars edit={false} count={5} value={4.5} size={30}
                                activeColor="#02b9d3"
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                            />
                            <Button onClick={() => { isAuth ? addToCart() : dispatch(setLoginShow(true)) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, border: 'none' }}>Add to Cart</Button>
                        </Col>
                    </Row>
                </Container>
            </div>


        </div>
    )
}

export default SingleProduct
