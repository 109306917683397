// export const serverUrl = 'http://localhost:8000' // Development
export const serverUrl = 'https://us-central1-rad-venture.cloudfunctions.net/api' // Production



export const STRIPE_PUBLISHABLE_KEY='pk_live_51QRm88G1Z2co7xp89tr7JF9gBaR5R9poMrS1h9sqeeu9F3KCuad6osS2FwmmOyyZErZvmnjRwHEHqTMReLAaS6Bj00tk1exDGa'

export const STRIPE_TEST_PUBLISHABLE_KEY='pk_test_51QRm88G1Z2co7xp80X8hxuisrnwDrqrcQKb0tZriDLlmOvUtnus5FMjYo1JWzsJCCigXPuS1ja5WirLKRb2HVSyk00jQaB1BTn'


export const MERCHANT_ID=''

export const STRIPE_API_URL =''