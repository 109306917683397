import React from 'react'
import {Container} from 'react-bootstrap'
const Help = () => {
  return (
    <div style={{ minHeight: '60vh' }}>
      <Container>
      <h1 style={{ padding: '50px 0',  fontWeight: 'bold' }}>Help</h1>

      </Container>

      
    </div>
  )
}

export default Help
